import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import ClientLayout from "./sections/client/Layout";
import Live from "./sections/client/Live";

import AdminLayout from "./sections/admin/Layout";
import Flights from "./sections/admin/Flights";
import Mlat from "./sections/admin/Mlat";
import Connections from './sections/admin/Connnections';
import Watch from "./common/Watch";
import Stream from "./common/Stream";
import LandingPage from './sections/landing';
import Settings from './sections/admin/Settings';

const theme = createTheme({
    typography: {
      fontFamily: [
        '"YourPreferredFont"', // Replace 'YourPreferredFont' with the font you want to use
        'Arial',
        'sans-serif',
      ].join(','),
    },
    palette: {
        primary: {
            main: '#0F3D68',
            text: '#0F3D68',
            light: '#55749499',
            white: '#FFFFFF',
        },
        background: {
            main: '#3F6186',
            mainLight: '#557494',
            green: '#61680F',
            greenLight: '#61680FBB',
            purple : '#4C0F68',
            purpleLight: '#4C0F68AA',
        }
    },
});
  
const Router = () => {
    // const isAuthenticated = () => {
    //   const accessToken = sessionStorage.getItem('accessToken');
    //   return !!accessToken;
    // };

    return (
        <ThemeProvider theme={theme}>
            <Routes>
                <Route path="/" element={<LandingPage />} />
                {/* <Route path="/" element={isAuthenticated() ? <Navigate replace to="/" /> : <Navigate replace to="/login" />} /> */}
                <Route element={<ClientLayout />}>
                    <Route path="/live" element={<Live />} />
                </Route>
                <Route element={<AdminLayout />}>
                    <Route path="/admin" element={<Flights />} />
                    <Route path="/admin/flights" element={<Flights />} />
                    <Route path="/admin/mlat" element={<Mlat />} />
                    <Route path="/admin/settings" element={<Settings />} />
                    <Route path="/admin/connStatus" element={<Connections />} />
                    <Route path="/admin/stream" element={<Stream />} />
                    <Route path="/admin/watch" element={<Watch />} />
                </Route>
            </Routes>            
        </ThemeProvider>
    );
};

export default Router;
