import React from 'react';
import { Box, Paper, Typography, TextField, Button, Stack } from '@mui/material';
import Loading from './Loading';

const host = process.env.REACT_APP_HOST || 'http://localhost:5000';

const LoginScreen = ({ children }) => {
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [loggedInUser, setLoggedInUser] = React.useState(undefined);
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoggedInUser(null);

        try{
            const response = await fetch(`${host}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });
    
            if (response.ok) {
                const data = await response.json();
                const encodedData = btoa(JSON.stringify(data));
                localStorage.setItem('user', encodedData);
                setLoggedInUser(response);
            } else {
                setLoggedInUser(null);
            }
        } catch (error) {
            console.error('Error logging in:', error);
            setLoggedInUser(null);
        }
    };
    
    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };
    
    React.useEffect(() => {
        const encodedUser = localStorage.getItem('user');
        if (encodedUser) {
            const decodedUser = JSON.parse(atob(encodedUser));
            const unlocked = decodedUser?.email != null;
            setLoggedInUser(unlocked ? decodedUser : null);
        } else {
            setLoggedInUser(null);
        }
    }, []);

    const loginElem = (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <Stack spacing={6}>
                <Typography variant="h2" align="center" sx={{ marginBottom: '2rem' }}>
                    APNT-ADSB
                </Typography>
                <Paper
                    sx={{
                    padding: '2rem',
                    maxWidth: '400px',
                    width: '100%',
                    borderRadius: '10px',
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        Login
                    </Typography>
                    <TextField
                        label="Username"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        onChange={handleUsernameChange}
                        value={username}
                    />
                    <TextField
                        label="Password"
                        variant="outlined"
                        type="password"
                        fullWidth
                        margin="normal"
                        onChange={handlePasswordChange}
                        value={password}
                    />
                    <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>
                        Submit
                    </Button>
                </Paper>
            </Stack>
        </Box>
    );

    return (
        loggedInUser === undefined ? <Loading /> : (loggedInUser ? children : loginElem)
    );
};

export default LoginScreen;